<template>
  <div class="st-pusher">
    <div class="st-content">
      <div class="st-content-inner" :class="isRTLPage? 'rtl' : 'ltr'">
        <div class="main clearfix" ref="main">
          <TopHeader :kindTheme="kindTheme" v-if="!isMobileApp" />
          <TopNav :kindTheme="kindTheme" v-if="!isMobileApp && !is404Page && !noBanner && !isFooterPage" />
          <router-view />
          <!-- <FormSubscribe/> -->
          <!-- <Footer /> -->
          <error-message-modal/>
          <b-toast
            :visible="messageShow"
            variant="info"
            :body-class="isRTLPage ? 'rtl' : 'ltr'"
            :title="$t('booking.notice')"
            auto-hide-delay="5000"
            :append-toast="false"
            :no-close-button="true"
            toaster="b-toaster-top-center"
            @hidden="toastHideCallback">
            {{ message }}
          </b-toast>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BToast } from 'bootstrap-vue';
import agencyMarketer from '@/mixins/agencyMarketer';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'ContentWrapper',
  components: {
    BToast,
    TopHeader: () => import('@/components/content/TopHeader/TopHeader'),
    TopNav: () => import('@/components/content/TopNav/TopNav'),
    // FormSubscribe: () => import('@/components/content/FormSubscribe'),
    // Footer: () => import("@/components/content/Footer"),
    ErrorMessageModal: () => import('@/components/content/ErrorMessageModal'),
  },
  mixins: [agencyMarketer],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      typeChannel: 'GET_TYPE_CHANNEL',
      currentPage: 'GET_CURRENT_PAGE',
      lang: 'GET_LANGUAGE',
      isRTLPage: 'GET_IS_RLT_PAGE',
      message: 'GET_ALERT_MESSAGE',
      messageShow: 'GET_ALERT_MESSAGE_SHOW',
    }),
    isMobileApp() {
      return this.typeChannel?.toLowerCase() === VUE_APP_CHANNEL_MOBILE_APP?.toLowerCase();
    },
    is404Page() {
      return this.typeChannel?.toLowerCase() === '404-page';
    },
    isFooterPage() {
      return this.currentPage === 'footer';
    },
    noBanner() {
      return Boolean(this.$route.query.afterBook);
    },
  },
  methods: {
    toastHideCallback() {
      this.$store.commit('SET_ALERT_MESSAGE', '');
      this.$store.commit('SET_ALERT_MESSAGE_SHOW', false);
    },
  },
};
</script>
